export enum Environment {
  Development = 'development',
  Production = 'production',
  Test = 'test',
}

const ENV: keyof typeof configs = process.env.NODE_ENV as keyof typeof configs;

const COMMON = {
  ENV: process.env.NODE_ENV as Environment,
  PLACES_API_KEY: 'AIzaSyCEBk8Lol5TvfVsFIw_g9izUi5Rm37QLHQ',
  SENTRY_DSN:
    'https://22d227615f024a409010d841066fd00a:a5d4192f49314508b28ba032af7c8960@o4505018749485056.ingest.sentry.io/4505018752237568',
};

const configs = {
  [Environment.Development]: {
    GTM_ID: '',
    HOTJAR_HJID: 0,
    HOTJAR_HJSV: 0,
    STRIPE_PUBLIC_KEY:
      'pk_test_51MvHxuKn4KeSFkGxFUlfAtN7DQDnSeW8rwrYCFRZBzFfRlriSK5eKX9HS8PtkflFKeirUc74LQ64heupk4yTg41K00zLA07TIp',
  },
  [Environment.Production]: {
    GTM_ID: 'GTM-PQ9D345',
    HOTJAR_HJID: 3479664,
    HOTJAR_HJSV: 6,
    STRIPE_PUBLIC_KEY:
      'pk_live_51MvHxuKn4KeSFkGxr3EIT0NCDXYVnjbfbDZEnaBrrNFb1hpmSp11Dv1mf78tRZR7TvfrL8rr4rqeJvz8dIRlnVAH00S9zkVjSo',
  },
};

export const config = { ...configs[ENV], ...COMMON };
